@charset "UTF-8";
.owl-carousel .owl-item img {
    height: auto;
}
.bg-gradient2 {
    background: linear-gradient(to top left, #ffffff, #ffffff) !important;
}
.text-orange {
    color: #ff472b !important;

}
.owl-loaded .owl-stage:after {
    content: "";
}
.owl-carousel:not(.owl-loaded) {
    flex-wrap: nowrap;
    overflow: hidden;
}
.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 1.5rem;
}
.owl-theme .owl-dots .owl-dot.active span {
    background-color: #0b80d2;
    border-color: #0b80d2;
}
.owl-theme .owl-dots .owl-dot span {
    margin: 5px;
}
.owl-dot-white .owl-dots .owl-dot span {
    background-color: #fff;
    border-color: #fff;
    opacity: 0.8;
}
.owl-dot-white .owl-dots .owl-dot.active span {
    background-color: #fff;
    border-color: #fff;
    opacity: 1;
}
*,
:after,
:before {
    box-sizing: inherit;
}
html {
    font-size: 62.5%;
    font-size-adjust: 100%;
    font-weight: 400;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
    margin: 0;
    overflow-x: hidden;
    font-family: Poppins, sans-serif;
    font-size: 1.4rem;
    line-height: 1.6;
    color: #666;
}
main {
    display: block;
    position: relative;
}
.page-wrapper {
    position: relative;
    transition: margin 0.4s;
}
.grey-section {
    background: #f3f6f9;
}
ul {
    padding-left: 1.5em;
}
.breadcrumb,
.category ul,
.comments ul,
.dropdown-box,
.filter-items,
.list,
.menu,
.menu ul,
.mobile-menu,
.mobile-menu ul,
.nav,
.nav-filters,
.nav ul,
.pagination,
.product-nav,
.product-tabs > div ul,
.select-menu > ul,
.widget-body,
.widget-body ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;
}
a:hover {
    color: #0b80d2;
}
:focus {
    outline: 0;
}
img {
    max-width: 100%;
    height: auto;
}
i {
    font-style: normal;
}
button:focus {
    outline: none;
}
.scrollable,
.sidebar-content {
    -webkit-overflow-scrolling: touch;
}
.scrollable::-webkit-scrollbar,
.sidebar-content::-webkit-scrollbar {
    height: 7px;
    width: 4px;
}
.scrollable::-webkit-scrollbar-thumb,
.sidebar-content::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}
.appear-animate {
    transform: translate3d(0, 0, 0) scale(1);
    will-change: transform, filter, opacity;
}
.scroll-top {
    position: fixed;
    right: 15px;
    bottom: 0;
    width: 40px;
    height: 40px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.3s, visibility 0.3s, opacity 0.3s;
    transform: translateY(40px);
    z-index: 9999;
    background-color: #43494e;
}
.scroll-top:hover {
    color: #fff;
}
@media (min-width: 768px) {
    .scroll-top.show {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fixedTop {
    0% {
        transform: translateY(-100%);
        transform-origin: center top 0px;
    }
    to {
        transform: translateY(0);
    }
}
.sticky-content.fix-top {
    top: 0;
}
.sticky-content.fixed {
    position: fixed;
    left: 0;
    right: 0;
    opacity: 1;
    transform: translateY(0);
    background: #fff;
    z-index: 1051;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}
.sticky-content.fixed.fix-top {
    animation: fixedTop 0.4s;
}
.w-100 {
    width: 100% !important;
}
.x-50 {
    left: 50% !important;
    transform: translateX(-50%) !important;
}
.x-50.y-50 {
    transform: translate(-50%, -50%) !important;
}
.y-50 {
    top: 50% !important;
    transform: translateY(-50%) !important;
}
.d-none {
    display: none !important;
}
.d-block {
    display: block !important;
}
.d-inline-block {
    display: inline-block !important;
}
.d-flex {
    display: flex !important;
}
.justify-content-center {
    justify-content: center !important;
}
.align-items-center {
    align-items: center !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }
    .d-lg-block {
        display: block !important;
    }
}
.font-primary {
    font-family: Poppins, sans-serif !important;
}
.font-secondary {
    font-family: "Open Sans", sans-serif !important;
}
.font-tertiary {
    font-family: "Segoe Script", "Savoye LET", sans-serif !important;
}
.font-weight-bold {
    font-weight: 700 !important;
}
.font-weight-semi-bold {
    font-weight: 600 !important;
}
.font-weight-normal {
    font-weight: 400 !important;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.text-none {
    text-transform: none !important;
}
.text-center {
    text-align: center !important;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}
.text-white {
    color: #fff !important;
}
.text-grey {
    color: #999 !important;
}
.text-body {
    color: #666 !important;
}
.text-dark {
    color: #222 !important;
}
.text-primary {
    color: #ff472b !important;
}
.ls-s {
    letter-spacing: -0.01em !important;
}
.ls-m {
    letter-spacing: -0.025em !important;
}
.ls-l {
    letter-spacing: -0.05em !important;
}
.lh-1 {
    line-height: 1 !important;
}
.bg-white {
    background-color: #fff !important;
}
.bg-dark {
    background-color: #222 !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: 400;
    margin: 0 0 2rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    line-height: 1.4;
    color: #222;
}
h1 {
    font-size: 4rem;
}
h2 {
    font-size: 3.4rem;
}
h3 {
    font-size: 3rem;
}
h4 {
    font-size: 2.4rem;
}
h5 {
    font-size: 1.8rem;
}
h6 {
    font-size: 1.5rem;
}
p {
    font-family: "Open Sans", sans-serif;
    font-size: 1.4rem;
    line-height: 1.86;
}
.list {
    margin-left: 2rem;
    font-size: 1.4rem;
    line-height: 1.86;
    color: #666;
}
.container,
.container-fluid {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

.container-fluid{
    /* overflow-x: auto; */
}

@media (max-width: 479px) {
    .container,
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.container-fluid {
    max-width: 1800px;
}
.container {
    max-width: 1220px;
}
.divider {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.4rem;
    height: 1.9rem;
    width: 1px;
    background-color: #e1e1e1;
}
.row {
    display: flex;
    flex-wrap: wrap;
}
.row > * {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}
.gutter-lg {
    margin-left: -15px;
    margin-right: -15px;
}
.gutter-lg > * {
    padding-right: 15px;
    padding-left: 15px;
}
.mb-0 {
    margin-bottom: 0rem !important;
}
.mb-1 {
    margin-bottom: 0.5rem !important;
}
.pt-1 {
    padding-top: 0.5rem !important;
}
.pb-1 {
    padding-bottom: 0.5rem !important;
}
.mb-2 {
    margin-bottom: 1rem !important;
}
.pt-2 {
    padding-top: 1rem !important;
}
.pb-2 {
    padding-bottom: 1rem !important;
}
.mb-3 {
    margin-bottom: 1.5rem !important;
}
.pb-3 {
    padding-bottom: 1.5rem !important;
}
.mt-4 {
    margin-top: 2rem !important;
}
.mb-4 {
    margin-bottom: 2rem !important;
}
.pb-4 {
    padding-bottom: 2rem !important;
}
.mt-5 {
    margin-top: 2.5rem !important;
}
.mb-5 {
    margin-bottom: 2.5rem !important;
}
.pt-5 {
    padding-top: 2.5rem !important;
}
.mb-6 {
    margin-bottom: 3rem !important;
}
.mt-7 {
    margin-top: 3.5rem !important;
}
.mb-7 {
    margin-bottom: 3.5rem !important;
}
.pt-7 {
    padding-top: 3.5rem !important;
}
.mb-8 {
    margin-bottom: 4rem !important;
}
.pt-8 {
    padding-top: 4rem !important;
}
.mb-9 {
    margin-bottom: 4.5rem !important;
}
.pt-9 {
    padding-top: 4.5rem !important;
}
.pb-9 {
    padding-bottom: 4.5rem !important;
}
.mt-10 {
    margin-top: 5rem !important;
}
.mb-10 {
    margin-bottom: 5rem !important;
}
.pt-10 {
    padding-top: 5rem !important;
}
.pb-10 {
    padding-bottom: 5rem !important;
}
.ml-0 {
    margin-left: 0rem !important;
}
.cols-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
}
@media (min-width: 480px) {
    .cols-xs-2 > * {
        max-width: 50%;
        flex: 0 0 50%;
    }
}
@media (min-width: 576px) {
    .cols-sm-3 > * {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
}
@media (min-width: 768px) {
    .cols-md-2 > * {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .cols-md-3 > * {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
    .cols-md-4 > * {
        max-width: 25%;
        flex: 0 0 25%;
    }
}
@media (min-width: 992px) {
    .cols-lg-3 > * {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
    .cols-lg-4 > * {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .cols-lg-5 > * {
        max-width: 20%;
        flex: 0 0 20%;
    }
    .pt-lg-0 {
        padding-top: 0rem !important;
    }
    .pt-lg-10 {
        padding-top: 5rem !important;
    }
    .pb-lg-10 {
        padding-bottom: 5rem !important;
    }
    .ml-lg-0 {
        margin-left: 0rem !important;
    }
}
@media (min-width: 1200px) {
    .cols-xl-5 > * {
        max-width: 20%;
        flex: 0 0 20%;
    }
}
@media (min-width: 576px) {
    .col-sm-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
}
@media (min-width: 768px) {
    .col-md-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }
    .col-md-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .col-md-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }
}
@media (min-width: 992px) {
    .col-lg-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .col-lg-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
    .col-lg-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }
    .col-lg-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
}
@media (min-width: 1200px) {
    .col-xl-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .col-xl-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
}
@media (min-width: 992px) {
    .order-lg-first {
        order: -1;
    }
}
.grid {
    margin: -1rem;
}
.grid img {
    object-fit: cover;
}
.grid .grid-item {
    padding: 1rem;
    transform: translate3d(0, 0, 0);
}
@keyframes fadeInUpShorter {
    0% {
        opacity: 0;
        transform: translate(0, 50px);
        transform-origin: 0 0;
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInUpShorter {
    animation-name: fadeInUpShorter;
}
@keyframes fadeInLeftShorter {
    0% {
        opacity: 0;
        transform: translate(50px, 0);
        transform-origin: 0 0;
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInLeftShorter {
    animation-name: fadeInLeftShorter;
}
@keyframes fadeInRightShorter {
    0% {
        opacity: 0;
        transform: translate(-50px, 0);
        transform-origin: 0 0;
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInRightShorter {
    animation-name: fadeInRightShorter;
}
@keyframes blurIn {
    0% {
        opacity: 0;
        filter: blur(20px);
        transform: scale(1.2);
    }
    to {
        opacity: 1;
        filter: blur(0);
        transform: none;
    }
}
.blurIn {
    animation-name: blurIn;
}
.appear-animate {
    visibility: hidden;
}
.appear-animation-visible {
    visibility: visible;
}
.banner {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font-size: 1rem;
}
.banner figure img {
    display: block;
    width: 100%;
    height: auto;
}
.banner .banner-content {
    position: relative;
}
.banner-subtitle {
    letter-spacing: -0.025em;
    line-height: 1;
}
.banner-title {
    line-height: 1;
}
.banner-fixed > .banner-content,
.banner-fixed > .container,
.banner-fixed > .container-fluid {
    position: absolute;
    z-index: 1;
}
.banner-fixed > .container,
.banner-fixed > .container-fluid {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.video-banner {
    position: relative;
}
.video-banner .btn-play {
    display: inline-block;
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s;
    border: 2px solid;
    line-height: 63px;
    width: 65px;
    height: 65px;
    font-size: 22px;
}
.video-banner .btn-play:hover {
    background-color: #0b80d2;
    border-color: #0b80d2;
}
.btn {
    display: inline-block;
    border: 2px solid #e4eaec;
    background-color: #e4eaec;
    color: #222;
    outline: 0;
    border-radius: 0;
    padding: 1em 2em;
    font-weight: 700;
    font-size: 1.4rem;
    font-family: Poppins, sans-serif;
    letter-spacing: -0.025em;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s, box-shadow 0.3s;
    white-space: nowrap;
    cursor: pointer;
}
.btn:active,
.btn:focus,
.btn:hover {
    color: #222;
    border-color: #cfdadd;
    background-color: #cfdadd;
}
.btn i {
    display: inline-block;
    margin-left: 0.4rem;
    line-height: 0;
}
.btn i:before {
    margin: 0;
}
.btn-solid {
    background-color: #fff;
    border-color: #fff;
    color: #222;
}
.btn-solid:active,
.btn-solid:focus,
.btn-solid:hover {
    border-color: #222;
    background-color: #222;
    color: #fff;
}
.btn.btn-rounded {
    border-radius: 3px;
}
.btn.btn-rounded:after,
.btn.btn-rounded:before {
    border-radius: 3px;
}
.btn.btn-block {
    display: block;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}
.btn.btn-lg {
    padding: 1.22em 2.69em;
}
.btn-link {
    padding: 0;
    color: #222;
    border: 0;
    background-color: transparent;
}
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
    background-color: transparent;
}
.btn-underline:active:after,
.btn-underline:focus:after,
.btn-underline:hover:after {
    transform: scaleX(1);
}
.btn-underline:after {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1px;
    width: 100%;
    border-bottom: 2px solid;
    transform: scaleX(0);
    transition: transform 0.3s;
    content: "";
}
.btn-underline i {
    margin-bottom: 2px;
}
.btn-underline.btn-underline-visible:after {
    transform: scaleX(1);
}
.btn-underline.btn-underline-width-sm:after {
    height: 1px;
    border-bottom: 0;
}
.btn-dark {
    color: #fff;
    border-color: #222;
    background-color: #222;
}
.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover {
    color: #fff;
    border-color: #343434;
    background-color: #343434;
}
.btn-dark.btn-solid {
    color: #222;
    border-color: #fff;
    background-color: #fff;
}
.btn-dark.btn-solid:active,
.btn-dark.btn-solid:focus,
.btn-dark.btn-solid:hover {
    border-color: #222;
    background-color: #222;
    color: #fff;
}
.btn-dark.btn-outline {
    color: #222;
    border-color: #222;
    background-color: transparent;
}
.btn-dark.btn-outline:active,
.btn-dark.btn-outline:focus,
.btn-dark.btn-outline:hover {
    background-color: #222;
    color: #fff;
}
.btn-dark.btn-link {
    background-color: transparent;
    color: #222;
}
.btn-dark.btn-link:active,
.btn-dark.btn-link:focus,
.btn-dark.btn-link:hover {
    color: #222;
}
.btn-dark.btn-underline:active,
.btn-dark.btn-underline:focus,
.btn-dark.btn-underline:hover {
    color: #222;
}
.elements > div {
    margin-bottom: 2rem;
}
.elements > div > a {
    display: block;
}
.element {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.2rem 0 2.4rem;
    border: 2px solid #e4eaec;
}
.element p {
    margin: 0;
    text-align: center;
    color: #222;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
}
.icon-box .icon-box-title {
    margin: 0 0 0.7rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: -0.025em;
    line-height: 1.6;
}
.icon-box p {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.72;
    letter-spacing: 0;
    color: #666;
}
.icon-box i:before {
    margin: 0;
}
.icon-box .icon-box-content {
    text-align: center;
}
.icon-box-icon {
    display: inline-block;
    margin-bottom: 2.4rem;
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 0;
    color: #222;
}
.mfp-wrap {
    z-index: 9999;
}
.mfp-bg {
    z-index: 9999;
    background: #000;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}
.mfp-bg.mfp-ready {
    opacity: 0.7;
}
.mfp-bg.mfp-removing {
    opacity: 0;
}
.mfp-container {
    padding: 4rem 2rem;
}
.mfp-close {
    transform: rotateZ(45deg);
    transition: opacity 0.3s;
    width: 18px;
    height: 18px;
}
.mfp-wrap .mfp-close {
    top: 20px;
    right: 20px;
}
.mfp-content .mfp-close {
    top: -25px;
    right: 0;
}
.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
    top: 15px;
    right: 0;
}
.mfp-close span {
    display: none;
}
.mfp-close:after,
.mfp-close:before {
    content: "";
    display: block;
    position: absolute;
    background: #ccc;
    width: 2px;
    height: 100%;
    top: 0;
    left: calc(50% - 1px);
}
.mfp-close:before {
    width: 100%;
    height: 2px;
    top: calc(50% - 1px);
    left: 0;
}
.mfp-arrow,
.pswp__button--arrow--left,
.pswp__button--arrow--right {
    width: 4.8rem;
    height: 4.8rem;
    color: #fff;
    border-radius: 50%;
    font-size: 1.6rem;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
    text-align: center;
    opacity: 0.5;
    right: 10%;
    transition: opacity 0.3s;
    border: 2px solid;
}
.pswp__ui > button:before {
    background: transparent;
    position: static;
    line-height: 44px;
}
.pswp__ui > button:hover {
    opacity: 1;
}
.pswp__button--arrow--right:before {
    content: "";
}
.mfp-arrow:after,
.mfp-arrow:before {
    content: "";
    position: static;
    display: inline;
    margin: 0;
    border: 0;
}
.mfp-arrow:after {
    content: none;
}
button.mfp-arrow {
    border: 2px solid;
}
.mfp-arrow-left,
.pswp__button--arrow--left {
    left: 10%;
}
.mfp-arrow-left:before,
.pswp__button--arrow--left:before {
    content: "";
}
.mfp-content > * {
    position: relative;
    margin: auto;
    background: #fff;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
}
.mfp-ready .mfp-content > * {
    opacity: 1;
}
.mfp-removing .mfp-content > * {
    opacity: 0;
}
.login-popup {
    max-width: 500px;
    padding: 3.5rem 5rem 4.8rem;
}
.login-popup .nav-link {
    padding: 0 0 1px;
    color: #222;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    line-height: 2.43;
}
.login-popup .nav-item {
    margin-bottom: -1px;
}
.login-popup .nav-item:not(:last-child) {
    margin-right: 1px;
}
.login-popup .nav-item .nav-link.active,
.login-popup .nav-item.show .nav-link {
    color: #222;
    border-color: #0b80d2;
}
.login-popup .tab-pane {
    padding-top: 2.8rem;
    padding-bottom: 0;
}
.login-popup form {
    margin-bottom: 2.3rem;
}
.login-popup .form-group {
    margin-bottom: 1.8rem;
}
.login-popup .form-group label {
    display: inline-block;
    margin-bottom: 8px;
    font-size: 1.3rem;
    font-family: "Open Sans", sans-serif;
    line-height: 1;
}
.login-popup .form-group .form-control {
    height: 3.7rem;
}
.login-popup .form-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    font-size: 1.2rem;
}
.login-popup .btn {
    height: 3.9rem;
    padding: 0;
    font-size: 1.3rem;
}
.login-popup .lost-link {
    text-align: right;
    color: #d26e4b;
}
.login-popup .lost-link:hover {
    text-decoration: underline;
}
.login-popup .form-choice label {
    display: block;
    margin-bottom: 0.3rem;
    font-size: 1.2rem;
    color: #999;
}
.newsletter-popup {
    display: flex;
    max-width: 70rem;
    text-align: center;
    background-position: 60% center;
    background-size: cover;
}
.newsletter-popup h4 {
    margin-bottom: 0.9rem;
    font-size: 1.6rem;
}
.newsletter-popup h2 {
    margin-bottom: 1.2rem;
    font-size: 2.8rem;
    line-height: 1.07;
    letter-spacing: -0.025em;
}
.newsletter-popup b {
    display: block;
    margin-top: 0.3rem;
    color: #222;
    font-size: 3rem;
    letter-spacing: 0.025em;
}
.newsletter-popup .btn {
    min-width: auto;
    padding: 0.5em 1.7em;
    font-size: 1.3rem;
}
.newsletter-popup p {
    max-width: 100%;
    margin-bottom: 1.8rem;
    padding: 0 0.5rem;
    font-size: 1.3rem;
    line-height: 2rem;
}
.newsletter-popup label {
    color: #999;
    font-size: 1.2rem;
}
.newsletter-popup .email {
    border: 0;
    color: #999;
    background: #f4f4f4;
}
.newsletter-popup .form-control {
    border: 1px solid #ccc;
    background: #fff;
    border-right: none;
}
.newsletter-content {
    margin-left: auto;
    max-width: 38.8rem;
    padding: 6.6rem 4.2rem 6.4rem;
}
.mfp-product .mfp-container {
    padding: 4rem 3rem 2rem;
}
.mfp-product .mfp-content {
    max-width: 92.3rem;
    margin: auto;
}
.mfp-product .product {
    background-color: #fff;
    padding: 3rem 2rem;
}
.mfp-product .mfp-close {
    position: absolute;
    top: -2.7rem;
    right: 0;
}
.pswp__bg {
    background-color: rgba(0, 0, 0, 0.7);
}
.pswp__img--placeholder--blank {
    background-color: #f2f3f5;
}
.pswp__ui--fit .pswp__caption,
.pswp__ui--fit .pswp__top-bar {
    background-color: transparent;
}
.pswp__caption__center {
    text-align: center;
}
.mfp-ready.mfp-zoom-popup .mfp-content {
    transform: scale(1);
}
.mfp-removing.mfp-zoom-popup .mfp-content,
.mfp-zoom-popup .mfp-content {
    transition: 0.2s ease-in-out;
    transform: scale(0.8);
}
.mfp-ready.mfp-flip-popup .mfp-content {
    transform: translateY(0) perspective(600px) rotateX(0);
}
.mfp-flip-popup .mfp-content,
.mfp-removing.mfp-flip-popup .mfp-content {
    transition: 0.3s ease-out;
    transform: translateY(-20px) perspective(600px) rotateX(20deg);
}
@media (max-width: 767px) {
    .mfp-product .product {
        padding: 2rem 1rem;
    }
    .mfp-arrow {
        color: #444;
    }
}
@media (min-width: 768px) {
    .mfp-product .product-gallery {
        margin-bottom: 0;
    }
    .mfp-product div.product-details {
        overflow-y: auto;
        padding-bottom: 0;
    }
}
@media (max-width: 767px) {
    .mfp-product div.product-details {
        margin-top: 2rem;
    }
    .newsletter-content {
        max-width: 36rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .login-popup {
        max-width: 500px;
        padding: 3.5rem 2rem;
    }
}
.header {
    letter-spacing: -0.01em;
    background: #fff;
    font-size: 1.4rem;
}
.header .container,
.header .container-fluid,
.header .inner-wrap {
    display: flex;
    align-items: center;
}
.header .inner-wrap {
    width: 100%;
}
.header-transparent {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 20;
    background: transparent;
}
.header-transparent .fixed {
    background: #222;
}
.header-bottom,
.header-middle,
.header-top {
    display: flex;
}
.has-center .header-left,
.has-center .header-right {
    flex: 1;
}
.has-center .header-right {
    justify-content: flex-end;
}
.has-center .header-center {
    margin-left: 2rem;
    margin-right: 2rem;
}
.header-center,
.header-left,
.header-right {
    display: flex;
    align-items: center;
}
.header-right {
    margin-left: auto;
}
.header-middle {
    padding-top: 2rem;
    padding-bottom: 1.7rem;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    font-weight: 600;
}
.header-middle .logo {
    margin-right: 3rem;
}
.header-middle .main-nav {
    margin-right: 0;
}
.header-middle .header-right .cart-dropdown {
    margin-right: 0 !important;
}
.logo {
    max-width: 200px;
    margin-bottom: 0.3rem;
}
.logo img {
    display: block;
}
.sticky-header.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1100;
    animation: fixedTop 0.4s;
}
@media (max-width: 991px) {
    .sticky-wrapper {
        height: auto !important;
    }
}
.mobile-menu-toggle {
    display: none;
    width: 24px;
    height: 16px;
    color: #0b80d2;
}
.mobile-menu-toggle svg {
    stroke: #222;
    margin: -17px -13px;
    width: 50px;
    height: 50px;
}
@media (max-width: 1199px) {
    .main-nav .menu > li {
        margin-right: 1.5rem;
    }
    .header-middle .logo {
        margin-right: 2rem;
    }
    .header-middle .divider {
        display: none;
    }
}
@media (max-width: 991px) {
    .header .divider,
    .header .main-nav {
        display: none;
    }
    .header-middle .header-left,
    .header-middle .header-right {
        flex: auto;
    }
    .header-middle .header-right {
        justify-content: flex-end;
    }
    .header-middle .header-center {
        margin-left: auto;
        margin-right: auto;
    }
    .header-middle .logo {
        margin-right: 0;
    }
    .mobile-menu-toggle {
        display: block;
        margin-right: 1.5rem;
    }
    .mobile-menu-toggle svg {
        stroke: #0b80d2;
    }
}
.menu {
    display: flex;
    align-items: center;
}
.menu a {
    display: inline-block;
}
.menu .menu-title {
    margin-bottom: 1rem;
    padding: 0 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
}
.menu li {
    position: relative;
    line-height: 1.5;
}
.menu li a {
    padding: 0.7rem 0 0.7rem 1rem;
}
.menu li .megamenu,
.menu li > ul {
    position: absolute;
    top: -9999px;
    left: 100%;
    margin: 0;
    box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -10px, 0);
}
.menu li > ul {
    min-width: 22.6rem;
    padding: 2rem 0;
    background: #fff;
}
.menu li > ul .submenu > a:after {
    content: "";
    position: absolute;
    right: 22px;
    top: 50%;
    width: 7px;
    height: 7px;
    border: solid #666;
    border-width: 2px 2px 0 0;
    transform: translateY(-50%) rotate(45deg);
}
.menu li > ul li {
    padding: 0 2rem;
}
.menu li .megamenu a,
.menu li > ul a {
    font-weight: 400;
    color: #666;
}
.menu li .megamenu a:hover,
.menu li > ul a:hover {
    color: #0b80d2;
}
.menu .show .megamenu,
.menu .show > ul,
.menu li:hover .megamenu,
.menu li:hover > ul {
    visibility: visible;
    opacity: 1;
    top: -2rem;
    transform: translate3d(0, 0, 0);
}
.menu > li {
    margin-right: 2.3rem;
}
.menu > li:last-child {
    margin-right: 0;
}
.menu > li > a {
    position: relative;
    padding: 2.2rem 0 2.6rem;
    letter-spacing: inherit;
    line-height: 1;
    text-transform: uppercase;
}
.menu > li .megamenu,
.menu > li > ul {
    left: -1.9rem;
}
.menu > li.show .megamenu,
.menu > li.show > ul,
.menu > li:hover .megamenu,
.menu > li:hover > ul {
    top: 100%;
}
.menu-active-underline > li > a:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid;
    transform-origin: right center;
    transform: scale(0, 1);
    transition: transform 0.3s;
}
.menu-active-underline > li.active > a,
.menu-active-underline > li:hover > a {
    color: inherit;
}
.menu-active-underline > li.active > a:before,
.menu-active-underline > li:hover > a:before {
    transform-origin: left center;
    transform: scale(1, 1);
}
.megamenu {
    display: flex;
    padding: 1rem;
    min-width: 92rem;
    background: #fff;
}
.megamenu ul {
    padding: 0;
}
.megamenu .row {
    flex: 1;
    padding: 0 1rem;
}
.megamenu .row > * {
    padding: 1.8rem 1rem 0.8rem;
}
.megamenu .menu-banner {
    padding: 0;
    overflow: hidden;
}
.megamenu .menu-banner figure {
    height: 100%;
}
.megamenu .menu-banner img {
    height: 100%;
    object-fit: cover;
}
.megamenu .menu-banner .btn-link:hover {
    color: #0b80d2;
}
.menu-banner1 .banner-content {
    left: 9%;
}
.menu-banner1 .banner-subtitle,
.menu-banner1 .banner-title {
    font-size: 3.6rem;
}
.menu-banner1 .banner-subtitle {
    margin-bottom: 0.4rem;
}
.menu-banner1 .banner-title {
    margin-bottom: 1.8rem;
    padding-left: 1.2rem;
    position: relative;
}
.menu-banner1 .banner-title span {
    display: inline-block;
    position: absolute;
    left: -0.9rem;
    top: 50%;
    font-size: 1.2rem;
    line-height: 1;
    transform: rotateZ(-90deg) translateX(0.6rem);
    letter-spacing: -0.1em;
}
.menu-banner2 .banner-content {
    bottom: 10%;
}
.menu-banner2 .banner-title {
    margin-bottom: 0.6rem;
    font-size: 2.6rem;
}
.menu-banner2 .banner-subtitle {
    font-size: 1.6rem;
}
.megamenu .menu-banner {
    padding: 0;
    overflow: hidden;
}
.megamenu .menu-banner figure {
    height: 100%;
}
.megamenu .menu-banner img {
    height: 100%;
    object-fit: cover;
}
.megamenu .menu-banner .btn-link:hover {
    color: #0b80d2;
}
.mobile-menu-wrapper {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    font-family: "Open Sans", sans-serif;
    transition: visibility 0.4s;
}
.mobile-menu-container {
    max-width: 296px;
    padding: 2rem 1.5rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #222529;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(-296px);
    transition: transform 0.4s;
}
.mobile-menu-container .mobile-menu {
    margin-bottom: 0.5rem;
    background: #222529;
}
.mobile-menu-container.scrollable::-webkit-scrollbar-thumb,
.mobile-menu-container .sidebar-content::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 7px;
    cursor: pointer;
}
.mobile-menu {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.025em;
    color: #e1e1e1;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.025em;
}
.mobile-menu ul {
    display: none;
    width: 100%;
}
.mobile-menu > li:first-child {
    padding-top: 0.5rem;
}
.mobile-menu > li:last-child {
    padding-bottom: 0.5rem;
}
.mobile-menu li a {
    display: block;
    position: relative;
    padding: 1.3rem 0.6rem 1.3rem 1rem;
}
.mobile-menu li i {
    display: inline-block;
    margin-bottom: 1px;
    font-size: 2rem;
    margin-right: 0.5rem;
    line-height: 0;
    vertical-align: middle;
}
.mobile-menu li:not(:last-child) {
    border-bottom: 1px solid #2e3237;
}
.mobile-menu .active > a,
.mobile-menu .expanded {
    background: #2e3237;
}
.mobile-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0;
    transition: opacity 0.4s;
}
.mobile-menu-close {
    position: fixed;
    left: calc(100vw - 50px);
    top: 25px;
    transition: opacity 0.3s;
    opacity: 0;
}
.mobile-menu-close i {
    font-size: 2.8rem;
    color: #e1e1e1;
}
.mmenu-anim {
    transform: translateY(30%);
}
.mmenu-anim,
.mmenu-anim > li {
    transition: transform 0.4s, opacity 0.3s;
    transition-timing-function: cubic-bezier(0.5, 0, 0.3, 1);
}
.mmenu-anim > li:nth-child(0) {
    opacity: 0;
    transform: translateY(0px);
}
.mmenu-anim > li:first-child {
    opacity: 0;
    transform: translateY(50px);
}
.mmenu-anim > li:nth-child(2) {
    opacity: 0;
    transform: translateY(100px);
}
.mmenu-anim > li:nth-child(3) {
    opacity: 0;
    transform: translateY(150px);
}
.mmenu-anim > li:nth-child(4) {
    opacity: 0;
    transform: translateY(200px);
}
.mmenu-anim > li:nth-child(5) {
    opacity: 0;
    transform: translateY(250px);
}
.mmenu-anim > li:nth-child(6) {
    opacity: 0;
    transform: translateY(300px);
}
.mmenu-anim > li:nth-child(7) {
    opacity: 0;
    transform: translateY(350px);
}
.mmenu-active {
    overflow: hidden;
}
.mmenu-active .mmenu-anim,
.mmenu-active .mmenu-anim > li {
    opacity: 1;
    transform: translateY(0);
}
.mmenu-active .page-wrapper {
    margin-left: 296px;
    margin-right: -296px;
}
.mmenu-active .mobile-menu-wrapper {
    visibility: visible;
}
.mmenu-active .mobile-menu-container {
    transform: translateX(0);
}
.mmenu-active .mobile-menu-overlay {
    opacity: 0.8;
}
.mmenu-active .mobile-menu-close {
    opacity: 1;
}
@media (max-width: 400px) {
    .mobile-menu-close {
        left: calc(100vw - 40px);
        top: 10px;
    }
}
.col-1 {
    max-width: 8.3333%;
    flex: 0 0 8.3333%;
}
.col-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
}
.col-3 {
    max-width: 25%;
    flex: 0 0 25%;
}
.col-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
}
.col-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
}
.col-6 {
    max-width: 50%;
    flex: 0 0 50%;
}
.col-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
}
.col-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
}
.col-9 {
    max-width: 75%;
    flex: 0 0 75%;
}
.col-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
}
.col-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
}
.col-12 {
    max-width: 100%;
    flex: 0 0 100%;
}
@media (min-width: 576px) {
    .col-sm-1 {
        max-width: 8.3333%;
        flex: 0 0 8.3333%;
    }
    .col-sm-2 {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }
    .col-sm-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .col-sm-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
    .col-sm-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }
    .col-sm-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .col-sm-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }
    .col-sm-8 {
        max-width: 66.6667%;
        flex: 0 0 66.6667%;
    }
    .col-sm-9 {
        max-width: 75%;
        flex: 0 0 75%;
    }
    .col-sm-10 {
        max-width: 83.3333%;
        flex: 0 0 83.3333%;
    }
    .col-sm-11 {
        max-width: 91.6667%;
        flex: 0 0 91.6667%;
    }
    .col-sm-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}
@media (min-width: 768px) {
    .col-md-1 {
        max-width: 8.3333%;
        flex: 0 0 8.3333%;
    }
    .col-md-2 {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }
    .col-md-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .col-md-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
    .col-md-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }
    .col-md-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .col-md-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }
    .col-md-8 {
        max-width: 66.6667%;
        flex: 0 0 66.6667%;
    }
    .col-md-9 {
        max-width: 75%;
        flex: 0 0 75%;
    }
    .col-md-10 {
        max-width: 83.3333%;
        flex: 0 0 83.3333%;
    }
    .col-md-11 {
        max-width: 91.6667%;
        flex: 0 0 91.6667%;
    }
    .col-md-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}
@media (min-width: 992px) {
    .col-lg-1 {
        max-width: 8.3333%;
        flex: 0 0 8.3333%;
    }
    .col-lg-2 {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }
    .col-lg-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .col-lg-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
    .col-lg-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }
    .col-lg-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .col-lg-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }
    .col-lg-8 {
        max-width: 66.6667%;
        flex: 0 0 66.6667%;
    }
    .col-lg-9 {
        max-width: 75%;
        flex: 0 0 75%;
    }
    .col-lg-10 {
        max-width: 83.3333%;
        flex: 0 0 83.3333%;
    }
    .col-lg-11 {
        max-width: 91.6667%;
        flex: 0 0 91.6667%;
    }
    .col-lg-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}
.footer-bottom {
    padding: 3.35rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.footer-bottom p {
    font-size: 1.6rem;
    letter-spacing: -0.01em;
}
p {
    font-family: Poppins, sans-serif;
}
.custom-scroll-top:before {
    content: "";
    position: absolute;
    left: 15px;
    top: 17px;
    width: 10px;
    height: 10px;
    border: solid #fff;
    border-width: 2px 2px 0 0;
    transform: rotate(-45deg);
}
@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}
.zoomIn {
    animation-name: zoomIn;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.fadeIn {
    animation-name: fadeIn;
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.fadeOut {
    animation-name: fadeOut;
}
figure {
    margin: 0;
}
.btn-gradient,
.demos span {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: #33363b;
}
.btn-gradient:before,
.demos span:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    width: 0;
    height: 0;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
    background: linear-gradient(166deg, #08c 35%, #5349ff 65%);
    transition: 0.3s;
}
.demos .grid-item a {
    width: 100%;
    overflow: hidden;
}
.btn-gradient.active:before,
.btn-gradient.gra-reversed:before,
.btn-gradient:focus:before,
.btn-gradient:hover:before,
.demos a:hover > span:before {
    width: 120%;
    padding-top: 120%;
    opacity: 1;
}
.btn-gradient {
    border: 0;
    padding: 1.2rem 2.95rem;
}
.btn-gradient.gra-reversed:focus:before,
.btn-gradient.gra-reversed:hover:before {
    width: 0;
    padding-top: 0;
    opacity: 0.2;
}
.btn-gradient.gra-boxed:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    border: 2px solid transparent;
    transition: border-color 0.3s;
}
.btn-gradient.gra-boxed:not(.active):not(:hover):after {
    border-color: rgba(204, 204, 204, 0.3);
}
.btn-gradient.btn {
    color: #fff;
    background: #33363b;
}
.btn-gradient.btn-rounded:before {
    border-radius: 50%;
}
.btn-gradient.btn-lg {
    padding: 1.36em 2.92em;
}
.header .btn-gradient {
    margin-bottom: 2px;
}
.bg-gradient {
    background: linear-gradient(to top left, #ff472b, #ff472b) !important;
}
@media (min-width: 1366px) {
    .d-xxl-none {
        display: none !important;
    }
    .d-xxl-block {
        display: block !important;
    }
}
@media (min-width: 1200px) {
    .d-xl-inline-block {
        display: inline-block !important;
    }
}
.text-gradient {
    background-color: #0b80d2;
    background-image: -webkit-gradient(linear-gradient(166deg, #08c 35%, #5349ff 65%));
    background-image: -webkit-gradient(linear, left top, left bottom, from(to right), color-stop(#08c), to(#5349ff));
    background-image: linear-gradient(166deg, #08c 35%, #5349ff 65%);
    background-size: 100%;
    -webkit-box-decoration-break: clone;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    box-decoration-break: clone;
    background-attachment: scroll;
    background-position: 0 0;
    background-repeat: repeat;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .text-gradient {
        background: none;
        color: #0b80d2;
    }
}
.lazy-img {
    height: 0;
    background: #f4f4f4;
}
.btn-dark.btn-solid:active,
.btn-dark.btn-solid:focus,
.btn-dark.btn-solid:hover {
    background: #33363b;
    border-color: #33363b;
}
@keyframes pencilAnimation {
    0% {
        width: 0;
        opacity: 0;
    }
    to {
        width: 106%;
        opacity: 1;
    }
}
.highlight-word {
    position: relative;
    display: inline-block;
}
.highlight-word .highlight {
    position: absolute;
    left: -3%;
    bottom: -1.9rem;
    /*  background: url(images/pencil-blue-line.png) no-repeat; */
    background-size: contain;
    width: 0;
    opacity: 0;
    height: 1.1rem;
    overflow: hidden;
    transition: width ease 1s, opacity ease 1s;
}
.appear-animation-visible .highlight-word .highlight.animated {
    opacity: 1;
    width: 106%;
}
.shape-overlay {
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.shape-overlays {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.same-height .flip-card,
.same-height .icon-box {
    height: 100%;
}
.text-lg {
    font-size: 1.2em;
}
.float-el {
    transition: transform linear 1s;
}
.flip-card {
    position: relative;
    perspective: 1000px;
}
.flip-card .flip-back,
.flip-card .flip-front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: transform 0.7s;
}
.flip-card .flip-front {
    transform: translate3d(0, 0, 1px) rotateY(0deg);
}
.flip-card .flip-back {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 0, -1px) rotateY(180deg);
}
.flip-card .flip-content {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: inherit;
    transform: translate3d(0, 0, 60px);
}
.flip-card:hover .flip-front {
    transform: translate3d(0, 0, -1px) rotateY(-180deg);
}
.flip-card:hover .flip-back {
    transform: translate3d(0, 0, 1px) rotateY(0deg);
}
.header .divider {
    height: 62px;
    margin-right: 2.4rem;
    background: rgba(255, 255, 255, 0.3);
}
.header-middle.fixed {
    border-bottom-color: transparent;
}
.change-log {
    font-size: 1.4rem;
    margin-bottom: 4px;
}
.header .btn-purchase {
    margin-bottom: 4px;
    border-width: 1px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
}
.sticky-header.fixed .btn:hover {
    background-color: #0b80d2;
}
.change-log {
    font-size: inherit;
    letter-spacing: -0.02em;
    margin-bottom: 2px;
}
.intro-banner {
    min-height: 82.8rem;
}
.intro-banner .banner-subtitle {
    font-size: 2em;
}
.intro-banner .banner-subtitle .gift {
    margin-left: 0.8rem;
    margin-right: 0.6rem;
    font-size: 2.4em;
}
.intro-banner .banner-title {
    margin-bottom: 3.4rem;
    font-size: 5em;
    text-indent: -2px;
    line-height: 1.06;
}
.intro-banner .container {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.intro-banner .banner-content {
    display: inline-block;
    padding-top: 100px;
    top: 50%;
    transform: translateY(-50%);
}
.intro-banner .btn-play {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    width: 7.8rem;
    height: 7.8rem;
    background: #222;
    border-radius: 50%;
    vertical-align: middle;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.intro-banner .btn-play svg {
    width: 50px;
    height: 50px;
    fill: #fff;
}
.intro-banner .custom-absolute-img1 {
    position: absolute;
    margin: 0;
    left: 52.7%;
    right: 0;
    bottom: 0;
}


.intro-banner .custom-absolute-img1 img {
    min-height: 600px;
    object-fit: cover;
    object-position: left top;
}

.intro-banner .custom-center-search {
    position:absolute;
    left:50%;
    top:2rem;
    /* transform: translate(-50%); */
    /* bottom: -14rem; */
    margin:0;
}
@media (max-width:1500px){
    .intro-banner .custom-center-search {
        position:absolute;
        left:50%;
        transform: translate(-50%);
    } 
}
.intro-banner .custom-absolute-img2 {
    position: absolute;
    /* left: 57.38%; */
    margin: 0;
    /* max-width: 40%; */
    bottom: -14rem;
}
.intro-banner .custom-absolute-img2 img {
    position: relative;
    z-index: 1;
}
.intro-banner .custom-absolute-img2 figure {
    top: -50px;
}
.intro-banner .custom-absolute-img2 figure:before {
    content: "";
    position: absolute;
    left: 10px;
    right: 30px;
    top: 10px;
    bottom: 10px;
    box-shadow: -20px 0px 100px 10px rgba(0, 0, 0, 0.5);
}
.intro-banner .mobile-content {
    position: absolute;
    left: 27%;
    right: 6.8%;
    top: 1.8%;
    bottom: 12%;
}
.intro-banner .mobile-image {
    position: relative;
    z-index: 1;
}
.intro-banner .video-desc {
    font-size: 1.6rem;
    line-height: 2.2rem;
    vertical-align: middle;
}
.intro-banner .video-desc span {
    font-size: 1.4rem;
}
.main-features {
    position: relative;
    padding-top: 9.5rem;
    z-index: 1;
}
.main-features h2 {
    font-size: 3rem;
    font-weight: 800;
    line-height: 3.2rem;
}
.main-features h2 span {
    margin-left: 1rem;
}
.main-features .icon-box {
    padding: 0px;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 220px;
    background: #fff;
}
.main-features svg {
    width: 6.5rem;
    height: 6.5rem;
    fill: #ccc;
}
.main-features .icon-box-title {
    font-size: 2.2rem;
    line-height: 2.6rem;
    margin-bottom: 1rem;
}
.main-features p {
    font-size: 12.88px;
    line-height: 22px;
}
.attractive-features .col-gift {
    padding: 48px 10px 0 12px;
}
.attractive-features .col-beautiful {
    padding: 39px 10px 0 6px;
}
.attractive-features h2 {
    margin-bottom: 1.3rem;
    font-size: 5rem;
}
.attractive-features h2 b {
    margin-left: -3px;
}
.attractive-features .enjoy {
    max-width: 30rem;
    margin-bottom: 2.6rem;
    letter-spacing: -0.015em;
}
.attractive-features h3 {
    max-width: 29rem;
    font-size: 4rem;
    letter-spacing: -0.03em;
    line-height: 0.9;
}
.attractive-features ol,
.attractive-features p {
    font-size: 1.6rem;
    line-height: 1.625;
}
.attractive-features .attractive {
    color: #02020c;
}
.attractive-features .list span {
    display: inline-block;
    min-width: 3.2rem;
    color: #0b80d2;
    font-weight: 700;
}
.feature-images {
    position: relative;
    width: 400px;
    height: 388px;
    margin: -17px auto 0;
    transform: translateX(-2px);
}
.feature-images:before {
    content: "";
    position: absolute;
    top: 12px;
    left: 18px;
    right: 22px;
    bottom: 16px;
    border-radius: 3rem;
    transform: rotate(45deg);
    box-shadow: 0 1rem 6rem rgba(0, 0, 0, 0.1);
}
.feature-images > * {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 8px 11px;
    transform: translate(-50%, -50%);
}
.feature-images > .feature1 {
    transform: translate(-50%, -100%);
}
.feature-images > .feature1:after {
    content: "eCommerce";
}
.feature-images > .feature2 {
    transform: translate(-100%, -50%);
}
.feature-images > .feature2:after {
    content: "HTML 5";
}
.feature-images > .feature3 {
    transform: translate(-50%, 0);
}
.feature-images > .feature3:after {
    content: "CSS 3";
}
.feature-images > .feature4 {
    transform: translate(0, -50%);
}
.feature-images .feature-image:after {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    color: #fff;
}
@keyframes particleDots1 {
    0%,
    to {
        width: 0;
        height: 0;
        opacity: 0;
    }
    10% {
        width: 1px;
        height: 1px;
        opacity: 1;
    }
    50% {
        width: 10px;
        height: 10px;
        opacity: 1;
    }
    90% {
        width: 5px;
        height: 5px;
    }
}
@keyframes particleDots2 {
    0%,
    to {
        width: 0;
        height: 0;
        opacity: 0;
    }
    10%,
    90% {
        width: 1px;
        height: 1px;
        opacity: 1;
    }
    50% {
        width: 7px;
        height: 7px;
    }
}
.particle span {
    position: absolute;
    border-radius: 50%;
    background: #d3d3d3;
    transform: translate(-50%, -50%);
    animation: infinite particleDots1 1.5s;
}
.particle1 {
    top: -2%;
    left: 53.9%;
    transform: rotate(315deg);
    padding: 0;
}
.particle2 {
    top: 100%;
    left: 54.7%;
    transform: rotate(225deg);
    padding: 0;
}
.particle2 + .particle2 {
    top: 58.1%;
    left: 99.9%;
    transform: rotate(45deg);
}
.particle2 span {
    background: #b5d9f1;
    animation: infinite particleDots2 2s;
}
.experience h3 {
    color: #238cd6;
}
.experience h3 > span {
    font-style: italic;
    padding-right: 0.5rem;
}
.experience h2 {
    font-size: 5rem;
    line-height: 0.95;
}
.experience .col-md-5 {
    padding-left: 4.2rem;
}
.experience p {
    line-height: 1.72;
}
.experience .shapes {
    height: 60rem;
}
.experience .shapes h3 {
    top: 35.5rem;
    left: 3rem;
    font-weight: 400;
}
.experience .shapes > * {
    position: absolute;
}
.experience .white-rect {
    border-radius: 3px;
    width: 263px;
    left: 24.8%;
    height: 173px;
    box-shadow: 0 1rem 6rem rgba(0, 0, 0, 0.1);
}
.experience .window3 {
    top: 5rem;
    right: 15.7%;
}
.experience .black-rect {
    border-radius: 3px;
    background: #282b30;
    width: 210px;
    height: 139px;
    top: 20rem;
    right: 26.7%;
}
.experience .window2 {
    top: 17.4rem;
    right: 11.8%;
}
.experience .window1 {
    top: 7rem;
}
.experience .drop {
    top: 47.1rem;
    left: 29.3%;
    width: 52px;
    height: 52px;
    border-radius: 52%;
    background: #29b6f6;
}
.experience .expshape1 {
    left: -13.15%;
    top: 14rem;
    fill: rgba(41, 182, 246, 0.85);
}
.experience .expshape2 {
    top: 27.9rem;
    left: -9.7%;
    fill: #0595ed;
}
.devotion {
    position: relative;
    padding-left: 15rem;
}
.devotion mark {
    z-index: 1;
    top: 0;
    left: 2rem;
    background: transparent;
    font-size: 8rem;
    text-align: center;
    line-height: 11.2rem;
}
.devotion mark:before {
    z-index: -1;
    background: linear-gradient(to top left, #5349ff, #08c);
}
.devotion:after {
    z-index: 1;
    top: 0;
    left: 2rem;
    border: 1px solid #2793ee;
}
.devotion:after,
.devotion mark:before {
    content: "";
    display: block;
    border-radius: 3rem;
    transform: rotate(45deg);
}
.devotion:after,
.devotion mark,
.devotion mark:before {
    position: absolute;
    width: 11.2rem;
    height: 11.2rem;
}
.devotion h3 {
    padding: 0.1em 0.2em;
    border-radius: 1rem;
    margin-bottom: 0.8rem;
}
.devotion p {
    font-size: 2rem;
    line-height: 1.2;
}
.demos-section {
    background: #282b30;
}
.demos-section mark {
    font-size: 2em;
}
.demos-section h2 {
    font-size: 4rem;
    line-height: 1.3;
    letter-spacing: -0.035em;
}
.demos-section p {
    font-size: 1.6rem;
    line-height: 1.5;
}
.demos-nav {
    letter-spacing: -0.025em;
}
.demos-nav a,
.demos-nav a.active {
    color: #fff;
}
.demos-nav .demo-label {
    position: absolute;
    top: 15px;
    right: -70px;
    display: block;
    text-align: center;
    width: 200px;
    transform: rotateZ(45deg);
    line-height: 28px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.05em;
    color: #fff;
}
.demos-nav .demo-label.label-popular {
    background-image: linear-gradient(90deg, #f55184 20%, #b73d9c 80%);
}
.demos-nav .demo-label.label-new {
    background-image: linear-gradient(90deg, #3b8df1 20%, #5449ff 80%);
}
.demos-nav .demo-label.label-trending {
    background-image: linear-gradient(90deg, #fe9f5f 20%, #ea5855 80%);
}
.demo-filters {
    padding-bottom: 4rem;
}
.demo-filters li:not(:last-child) {
    margin-right: 2rem;
}
.demo-filters a {
    position: relative;
    display: block;
    background: transparent;
    padding: 1.44em 2.72em;
    border-radius: 3px;
    margin-bottom: 1rem;
}
.demos a {
    display: inline-block;
}
.demos span {
    display: inline-block;
    margin: -16px 0 1.25vw;
    padding: 0.85em 1.3em;
    background-color: #33363b;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
}
.demos svg {
    vertical-align: -7px;
    fill: #fff;
}
.demos img {
    display: block;
    margin: auto;
    border-radius: 3px;
    width: 100%;
}
.elements-section h2,
.features-section h2,
.variation-section h2 {
    font-size: 4rem;
}
.features-section > p,
.variation-section p {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: -0.03em;
}
.features-section p {
    color: #999;
}
.features svg {
    fill: #0b80d2;
    stroke: #0b80d2;
    stroke-dashoffset: 1500;
    stroke-dasharray: 1500;
    transition: transform 0.3s, fill 0.5s;
}
.features > :nth-child(2n) svg {
    fill: #a33188;
    stroke: #a33188;
}
.features > div {
    margin-bottom: 2.1rem;
}
.features .icon-box {
    padding: 6.2rem 5rem 5.9rem;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.05), 0 0 1rem rgba(0, 0, 0, 0.05);
    overflow: hidden;
}
.features .icon-box:hover svg {
    transform: scale(1.4);
    stroke-dashoffset: 0;
    fill: transparent;
    transition: transform 0.3s, fill 0.5s, stroke-dashoffset 6s;
}
.features .icon-box-title {
    margin: 6px 0 1.4rem;
    font-size: 2rem;
    line-height: 1.3;
    letter-spacing: 0;
    text-transform: none;
}
.variation-section {
    margin-top: -16.7rem;
}
.variation-section .owl-carousel {
    width: 140%;
    margin-left: -20%;
    margin-right: -20%;
}
.variation-section .owl-carousel img {
    max-height: 17.2rem;
}
.variation-section img {
    object-fit: cover;
    position: absolute;
    top: 1.7rem;
    border-radius: 2rem;
}
.variation-section svg {
    width: 100%;
    fill: #fff;
    transition: transform 0.5s;
}
.variation-section span {
    margin: 1.8rem 0 4.2rem;
    color: #fff;
    font-size: 2rem;
    letter-spacing: -0.03em;
    transition: transform 0.3s;
}
.elements-section h3 {
    font-size: 1.6rem;
    margin-bottom: 3px;
}
.elements-section .element {
    padding: 1.2rem 0 2.6rem;
    margin-bottom: 1rem;
    background: #fff;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.05), 0 0 5px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s, background-color 0.3s, color 0.3s;
}
.elements-section .element:hover {
    transform: scale(1.3);
}
.elements-section p {
    font-size: 1.6rem;
}
.elements-section svg {
    margin: 23px 0 8px;
    fill: #666;
}
.elements > div:hover {
    z-index: 1;
}
footer .row {
    margin-top: -8.8rem;
}
footer .icon-box {
    display: inline-block;
    width: 100%;
    padding: 5rem;
    border-radius: 1rem;
    background: #282b30;
    box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.05);
}
footer .icon-box-icon svg {
    fill: #fff;
}
footer h2 {
    font-size: 5rem;
}
footer h3 {
    font-size: 4rem;
}
footer h3 b {
    color: #246de2;
}
footer .list {
    font-size: 1.8rem;
    letter-spacing: 0.01em;
}
footer .list svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-77%, -50%);
    fill: #999;
}
.footer .icon-box-title {
    font-size: 2rem;
    text-transform: none;
}
.mfp-bg {
    background-color: rgba(0, 0, 0, 0.8);
}
.mfp-video-popup .mfp-content {
    max-width: 1000px;
}
.mfp-video-popup video {
    width: 100%;
}
.minipopup-area {
    display: none;
}
@media (min-width: 420px) {
    .intro-banner .btn-dark {
        margin-right: 0.7rem;
    }
}
@media (min-width: 480px) {
    .mr-xs-3 {
        margin-right: 1.5rem;
    }
}
@media (min-width: 576px) {
    .variation-section img {
        transform: translate3d(0px, -160px, 90px) rotate3d(1, 0, 0, -90deg);
        transition: transform 0.5s;
    }
    .variation-section .layout {
        display: block;
        perspective: 500px;
        backface-visibility: visible;
        transform-style: preserve-3d;
    }
    .variation-section .layout:hover img {
        transform: none;
    }
    .variation-section .layout:hover svg {
        transform: translate3d(0px, 120px, -200px) rotate3d(1, 0, 0, 90deg);
    }
    .variation-section .layout:hover h4 {
        transform: translateY(-26px);
    }
}
@media (min-width: 768px) {
    .experience .devotion {
        margin-left: -12rem;
    }
    .p-max-width {
        max-width: 66rem;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 992px) {
    .attractive-features .col-features {
        margin-left: -8.3333%;
    }
    .experience .devotion {
        margin-left: -6rem;
    }
    footer .list {
        margin-left: 4rem;
    }
    footer .list li:not(:last-child) {
        margin-right: 9rem;
    }
}
@media (min-width: 1200px) {
    .intro-banner .banner-title {
        font-size: 5em;
    }
    .intro-banner .video-banner {
        margin-left: 6rem;
    }
    .experience .devotion {
        margin-left: 0;
    }
}
@media (max-width: 1220px) {
    .variation-section .overflow-hidden {
        margin: 0 -20px;
    }
}
@media (max-width: 1199px) {
    .features .icon-box {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .demo-filters li:not(:last-child) {
        margin-right: 1rem;
    }
    .demo-filters a {
        padding: 1.44em 1.72em;
    }
    .intro-banner .banner-title {
        font-size: 3.5em;
    }
    .features .icon-box {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: 3rem;
        padding-right: 3rem;
    }
}
@media (max-width: 991px) {
    .intro-banner p {
        margin-left: auto;
        margin-right: auto;
    }
    .mobile-menu-toggle svg {
        stroke: #fff;
    }
    .intro-banner {
        height: auto;
        text-align: center;
        font-size: 0.9rem;
    }
    .intro-banner .container {
        position: relative;
    }
    .intro-banner .banner-content {
        transform: none;
    }
    .intro-banner .custom-absolute-img1 {
        left: 10%;
    }
    .intro-banner .custom-absolute-img1 img {
        min-height: 0;
        max-height: 500px;
    }
    .intro-banner .custom-absolute-img2 {
        bottom: -10rem;
    }
    .attractive-features .col-gift {
        padding-left: 5vw;
    }
    .attractive-features .col-beautiful {
        padding-top: 76px;
    }
    footer .list {
        flex-direction: column;
    }
}
@media (max-width: 767px) {
    .header .btn-purchase {
        display: none;
    }
    .main-features .icon-box,
    footer .icon-box {
        max-width: 40rem;
        margin-left: auto;
        margin-right: auto;
    }
    .attractive-features h2 {
        font-size: 4rem;
    }
    .attractive-features h3,
    .elements-section h2,
    .features-section h2,
    .variation-section h2 {
        font-size: 3.1rem;
    }
}
@media (max-width: 575px) {
    .attractive-features .col-gift {
        order: -1;
        padding: 0 10px 8rem 7vw;
    }
    .attractive-features .col-beautiful {
        padding-left: 7vw;
    }
    .elements > div {
        max-width: 30rem;
        margin-left: auto;
        margin-right: auto;
    }
    .attractive-features .col-beautiful {
        padding-top: 8rem;
    }
    .variation-section .layout img {
        width: 306px;
        top: 3rem;
        left: 50%;
        border-radius: 2px 2px 2rem 2rem;
        opacity: 0;
        transform: translate(-50%);
        transition: opacity 0.3s;
    }
    .variation-section .layout:hover img {
        opacity: 1;
    }
}
@media (max-width: 479px) {
    .variation-section .overflow-hidden {
        margin: 0 -15px;
    }
}
