button.button-component {
  all: unset;
  padding: 12px 32px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  border-radius: 6px;
}

button.button-component.normal {
  color: white;
  background-color: #e13019;
  box-shadow: 0px 2.93902px 2.93902px rgba(0, 0, 0, 0.25);
}

button.button-component.normal:hover {
  background-color: #595959;
}

button.button-component.underline {
  text-decoration: underline;
  color: #e13019;
}

button.button-component.underline:hover {
  color: #595959;
}