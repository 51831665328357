.imageStyles1 {
    background-repeat: no-repeat !important;
    background-position: 0px 0px !important;
}

.footer {
    background-image: url("./../../assets/home/bg2.png");
    background-size: cover;
    background-color: "#212629";
    background-size: "110%";
}

.MenuMovilContent {
    background-color: white;
    width: 100%;
    height: 70px;
    display: none;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    position: relative;
    z-index: 6;
}

.MenuMovilContent .imgFirst {
    width: 130px;
}

.MenuMovilContent .imgLast {
    width: 40px;
}

.MenuMovilOptions {
    position: fixed;
    width: 100%;
    top: 0px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    transition: 0.2s;
    z-index: 99;
}

.MenuMovilOptions .Options {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.MenuMovilOptions .Options .href {
    font-size: 30px;
    font-weight: bold;
    color: #e13019;
    text-align: center;
    margin: 20px;
    line-height: 1;
}

.section4 .qualityImages {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 50px;
    padding-top: 0px;
}

.section4 .qualityImages img {
    width: 256px;
    margin: 24px;
    margin-top: 0px;
}

.container-fluid {
    max-width: none !important;
}

.MenuMovilOptions .Options .href2 {
    font-weight: bold;
    font-size: 30px;
    background-color: #e13019;
    color: white;
    padding: 21px;
    border-radius: 9px;
    margin: 20px;
}

.Close {
    background-color: white;
    display: flex;
    justify-content: flex-end;
}

.Close h1 {
    font-size: 21px;
    font-family: cursive;
    background-color: #e13019;
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px;
    border-radius: 4px;
}

.LineCardText {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    font-size: 30px;
    font-weight: bold;
}

.ContentAllFSC {
    display: flex;
    flex-direction: row-reverse;
}

.ContentAllFSC .content3 {
    width: 100%;
}

.ContentAllFSC .FSCHome {
    width: 100%;
    max-width: 500px;
}

/* .ContentAllFSC .FSCHome:focus {
    outline: 4px solid #e13019;
} */

.child1 .subChild1ContentBtn {
    display: flex;
    flex-direction: row;
}

.footer .aStyle2 {
    position: relative;
    padding: 2.2rem 0 2.6rem;
    letter-spacing: inherit;
    line-height: 1;
    text-transform: uppercase;
    color: white !important;
}

.page-content {
    margin-top: -648px;
    position: relative;
}

/* slider --------------------------- */
.sliderHome {
    height: 570px;
    width: 100%;
    overflow: hidden;
    margin: 0px;
    filter: brightness(35%);
    animation: hidde 30s infinite alternate ease-in-out;
}

/* //?padin slider */

.pts1 {
    padding-top: 100px;
}


.aStyle3 {
    color: white;
    font-size: 15px;
    margin: 0px;
}

.aStyle3:hover {
    color: rgb(221, 221, 221);
    font-size: 15px;
    margin: 0px;
}

.sliderHome ul {
    display: flex;
    padding: 0;
    width: 400%;
    opacity: 1;
    animation: cambio 30s infinite alternate steps(1);
}

.sliderHome li {
    width: 100%;
    list-style: none;
}

.sliderHome img {
    width: 100%;
}

@keyframes hidde {
    0% {
        opacity: 1;
    }

    19% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    45% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    55% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes cambio {
    0% {
        margin-left: 0;
    }

    20% {
        margin-left: 0;
    }

    25% {
        margin-left: -150%;
    }

    45% {
        margin-left: -150%;
    }

    50% {
        margin-left: -300%;
    }

    70% {
        margin-left: -300%;
    }

    75% {
        margin-left: -300%;
    }

    100% {
        margin-left: -300%;
    }
}

.child2FormQuota {
    margin-left: -84px;
    margin-top: -640px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.child2FormQuota .subChild1 {
    display: flex;
    flex-direction: column;
    background-color: #181634;
    color: white;
    padding: 40px;
    border-radius: 10px;
    position: relative;
    top: 100px;
    width: 531px;
    box-shadow: 0px 0px 28px 0px #000000c2;
    z-index: 2;
    margin-top: 30px;
    margin-left: 80px;
}

.child2FormQuota .subChild1 h1 {
    text-align: center;
    color: white;
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: 50px;
    font-weight: bolder;
}

.child2FormQuota .subChild1 span {
    font-size: 13px;
    margin: 8px;
    margin-left: 0px;
    font-weight: bolder;
}

.child2FormQuota .subChild1 input {
    padding: 10px;
    background-color: #2c2a4a;
    border: 0px;
    border-radius: 7px;
    margin-bottom: 15px;
    color: white;
}

.has-center .header-left a {
    padding: 0px !important;
    height: 58px !important;
}

.child2FormQuota .subChild1 button {
    padding: 10px;
    background-color: #e13019;
    color: white;
    border-radius: 6px;
    border: 0px;
    margin-top: 19px;
    cursor: pointer;
    transition: 0.2s;
    font-size: 14px;
}

.child2FormQuota .subChild1 button:hover {
    color: #e13019;
    background-color: white;
}

.page-content .banner-content .child1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 124%;
}

.page-content .banner-content .child1 h1 {
    color: white;
    font-size: 26px;
    margin: 15px;
}

.page-content .banner-content .child1 div input {
    font-size: 20px;
    width: 481px;
    padding: 16px;
    border-radius: 6px;
    border: 0px;
    margin-right: 15px;
}

.page-content .banner-content .child1 div button {
    padding: 12px;
    background-color: #e13019;
    border: 0px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;
    font-weight: bold;
    font-size: 14px;
    height: 100%;
}

.page-content .banner-content .child1 div button:hover {
    color: #c53724;
    background-color: white;
}

.header-transparent2 {
    position: relative !important;
}

.header-middle2 {
    border: 0px !important;
}

.content3 h1 {
    padding-inline: 60px;
    text-align: center;
    font-size: 50px;
    margin-top: 90px;
}

.content3 span {
    color: #ff472b;
}

.divContentLetter h3 {
    color: white;
    margin: 10px;
    font-size: 18px;
}

.divContentLetter h2 {
    color: white;
    margin: 10px;
    font-size: 13px;
}

.content3 p {
    padding-inline: 6%;
    text-align: left;
    margin-top: 30px;
}

.content3 ul {
    padding-inline: 10%;
    text-align: left;
    margin-top: 30px;
}

.subsection4Other {
    text-align: center;
    padding: 90px;
    padding-top: 0px;
    margin-top: 60px;
}

.subsection4Other h1 {
    font-size: 50px;
}

.subsection4Other h3 {
    font-weight: bold;
    margin-top: -50px;
}

.subsection4Other p {
    font-size: 16px;
}

.redesSociales {
    display: flex;
    justify-content: flex-end;
}

.redesSociales img {
    height: auto;
    width: 26px;
    margin: 7px;
}

.ItemClass {
    font-size: 16px;
    border-bottom: 2px solid #c7c7c7;
    padding-bottom: 6px;
    color: #181634ad;
    padding-top: 6px;
    padding-left: 14px;
    cursor: pointer;
    transition: 0.1s;
}

.ItemClass:hover {
    background-color: #dddddd;
}

.content4 {
    /* background-color: #ff472b; */
    background-color: #ff745e;
    /* color: white; */
    color: #050505;
    padding-inline: 0px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.content4 h1 {
    font-size: 50px;
    color: white;
    text-align: center;
}

.content4 h3 {
    font-weight: bold;
}

.content4 p {
    font-size: 16px;
    text-align: center;
    width: 62%;
    margin: 0 auto;
}

.owl-carousel .owl-item img {
    height: auto;
}

.owl-loaded .owl-stage:after {
    content: "";
}

.owl-carousel:not(.owl-loaded) {
    flex-wrap: nowrap;
    overflow: hidden;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 1.5rem;
}

.owl-theme .owl-dots .owl-dot.active span {
    background-color: #0b80d2;
    border-color: #0b80d2;
}

.owl-theme .owl-dots .owl-dot span {
    margin: 5px;
}

.owl-dot-white .owl-dots .owl-dot span {
    background-color: #fff;
    border-color: #fff;
    opacity: 0.8;
}

.owl-dot-white .owl-dots .owl-dot.active span {
    background-color: #fff;
    border-color: #fff;
    opacity: 1;
}

*,
:after,
:before {
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    font-size-adjust: 100%;
    font-weight: 400;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
    overflow-x: hidden;
    font-family: Poppins, sans-serif;
    font-size: 1.4rem;
    line-height: 1.6;
    color: #666;
}

main {
    display: block;
    position: relative;
}

.page-wrapper {
    position: relative;
    transition: margin 0.4s;
}

.grey-section {
    background: #f3f6f9;
}

ul {
    padding-left: 1.5em;
}

.breadcrumb,
.category ul,
.comments ul,
.dropdown-box,
.filter-items,
.list,
.menu,
.menu ul,
.mobile-menu,
.mobile-menu ul,
.nav,
.nav-filters,
.nav ul,
.pagination,
.product-nav,
.product-tabs>div ul,
.select-menu>ul,
.widget-body,
.widget-body ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;
}

a:hover {
    color: #0b80d2;
}

:focus {
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
    /* outline: 4px solid #407803 !important; */
}

/* :focus { outline: none; } */

img {
    max-width: 100%;
    height: auto;
}

i {
    font-style: normal;
}

/* button:focus {
    outline: none;
} */

button:focus {
    outline: 4px dashed orange;
}

div[tabindex="0"]:focus {
    outline: 4px dashed orange;
}

.scrollable,
.sidebar-content {
    -webkit-overflow-scrolling: touch;
}

.scrollable::-webkit-scrollbar,
.sidebar-content::-webkit-scrollbar {
    height: 7px;
    width: 4px;
}

.scrollable::-webkit-scrollbar-thumb,
.sidebar-content::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

.appear-animate {
    transform: translate3d(0, 0, 0) scale(1);
    will-change: transform, filter, opacity;
}

.scroll-top {
    position: fixed;
    right: 15px;
    bottom: 0;
    width: 40px;
    height: 40px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.3s, visibility 0.3s, opacity 0.3s;
    transform: translateY(40px);
    z-index: 9999;
    background-color: #43494e;
}

.scroll-top:hover {
    color: #fff;
}

@media (min-width: 768px) {
    .scroll-top.show {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fixedTop {
    0% {
        transform: translateY(-100%);
        transform-origin: center top 0px;
    }

    to {
        transform: translateY(0);
    }
}

.sticky-content.fix-top {
    top: 0;
}

.sticky-content.fixed {
    position: fixed;
    left: 0;
    right: 0;
    opacity: 1;
    transform: translateY(0);
    background: #fff;
    z-index: 1051;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.sticky-content.fixed.fix-top {
    animation: fixedTop 0.4s;
}

.w-100 {
    width: 100% !important;
}

.x-50 {
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.x-50.y-50 {
    transform: translate(-50%, -50%) !important;
}

.y-50 {
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-flex {
    display: flex !important;
}

.justify-content-center {
    justify-content: center !important;
}

.align-items-center {
    align-items: center !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

@media (min-width: 782px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-block {
        display: block !important;
    }
}

.font-primary {
    font-family: Poppins, sans-serif !important;
}

.font-secondary {
    font-family: "Open Sans", sans-serif !important;
}

.font-tertiary {
    font-family: "Segoe Script", "Savoye LET", sans-serif !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-semi-bold {
    font-weight: 600 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-none {
    text-transform: none !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-white {
    color: #fff !important;
}

.text-grey {
    color: #999 !important;
}

.text-body {
    color: #666 !important;
}

.text-dark {
    color: #222 !important;
}

.text-primary {
    color: #0b80d2 !important;
}

.ls-s {
    letter-spacing: -0.01em !important;
}

.ls-m {
    letter-spacing: -0.025em !important;
}

.ls-l {
    letter-spacing: -0.05em !important;
}

.lh-1 {
    line-height: 1 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-dark {
    background-color: #222 !important;
}

.container,
.container-fluid {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 479px) {

    .container,
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.container-fluid {
    max-width: 1800px;
}

.container {
    max-width: 1220px;
}

.divider {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.4rem;
    height: 1.9rem;
    width: 1px;
    background-color: #e1e1e1;
}

.banner-subtitle {
    font-size: 16px !important;
    border-bottom: 2px solid white !important;
}

.banner-title {
    font-size: 40px !important;
    font-family: system-ui !important;
}

.banner {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font-size: 1rem;
}

.banner figure img {
    display: block;
    width: 100%;
    height: auto;
}

.banner .banner-content {
    position: relative;
}

.banner-title {
    line-height: 1;
}

.banner-fixed>.banner-content,
.banner-fixed>.container,
.banner-fixed>.container-fluid {
    position: absolute;
    z-index: 1;
}

.banner-fixed>.container,
.banner-fixed>.container-fluid {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.video-banner {
    position: relative;
}

.video-banner .btn-play {
    display: inline-block;
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s;
    border: 1px solid;
    line-height: 49px;
    width: 51px;
    height: 51px;
    font-size: 22px;
}

.video-banner .btn-play:hover {
    background-color: #0b80d2;
    border-color: #0b80d2;
}

.header {
    letter-spacing: -0.01em;
    background: #fff;
    font-size: 1.4rem;
}

.header .container,
.header .container-fluid,
.header .inner-wrap {
    display: flex;
    align-items: center;
}

.header .inner-wrap {
    width: 100%;
}

.header-transparent {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 20;
    background: transparent;
}

.header-transparent .fixed {
    background: #222;
}

.header-bottom,
.header-middle,
.header-top {
    display: flex;
}

.has-center .header-left,
.has-center .header-right {
    flex: 1;
}

.has-center .header-right {
    justify-content: flex-end;
}

.has-center .header-center {
    margin-left: 2rem;
    margin-right: 2rem;
}

.header-center,
.header-left,
.header-right {
    display: flex;
    align-items: center;
}

.header-right {
    margin-left: auto;
}

.header-middle {
    padding-top: 0px !important;
    padding-bottom: 1.7rem;
    color: #fff;
    font-weight: 600;
    border: 0px !important;
}

.NewColorMenu {
    background-color: white !important;
}

.header-middle .logo {
    margin-right: 3rem;
}

.header-middle .main-nav {
    margin-right: 0;
}

.header-middle .header-right .cart-dropdown {
    margin-right: 0 !important;
}

.logo {
    max-width: 200px;
    margin-bottom: 0.3rem;
}

.FSCHome .acordeonNsNs {
    height: 462px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;
}

.acordeonNsNs::-webkit-scrollbar {
    -webkit-appearance: none;
}

.acordeonNsNs::-webkit-scrollbar:vertical {
    width: 10px;
}

.acordeonNsNs::-webkit-scrollbar-button:increment,
.acordeonNsNs::-webkit-scrollbar-button {
    display: none;
}

.acordeonNsNs::-webkit-scrollbar:horizontal {
    height: 10px;
}

.acordeonNsNs::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.acordeonNsNs::-webkit-scrollbar-track {
    border-radius: 10px;
}

.logo img {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    width: 320px;
    max-width: 240px;
    margin: -11px;
}

.sticky-header.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1100;
    animation: fixedTop 0.4s;
}

@media (max-width: 991px) {
    .sticky-wrapper {
        height: auto !important;
    }
}

.mobile-menu-toggle {
    display: none;
    width: 24px;
    height: 16px;
    color: #0b80d2;
}

.mobile-menu-toggle svg {
    stroke: #222;
    margin: -17px -13px;
    width: 50px;
    height: 50px;
}

@media (max-width: 1199px) {
    .main-nav .menu>li {
        margin-right: 1.5rem;
    }

    .header-middle .logo {
        margin-right: 2rem;
    }

    .header-middle .divider {
        display: none;
    }
}

@media (max-width: 991px) {

    .header .divider,
    .header .main-nav {
        display: none;
    }

    .header-middle .header-left,
    .header-middle .header-right {
        flex: auto;
    }

    .header-middle .header-right {
        justify-content: flex-end;
    }

    .header-middle .header-center {
        margin-left: auto;
        margin-right: auto;
    }

    .header-middle .logo {
        margin-right: 0;
    }

    .mobile-menu-toggle {
        display: block;
        margin-right: 1.5rem;
    }

    .mobile-menu-toggle svg {
        stroke: #0b80d2;
    }
}

.menu {
    display: flex;
    align-items: center;
}

.menu a {
    display: inline-block;
}

.menu a:hover {
    color: #a01300 !important;
}

.menu a:focus {
    color: #a01300 !important;
    outline: 1px solid rgb(17, 17, 17);
}


.menu .menu-title {
    margin-bottom: 1rem;
    padding: 0 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
}

.menu li {
    position: relative;
    line-height: 1.5;
}

.menu li a {
    font-size: 1.6rem;
    padding: 0.7rem 0 0.7rem 1rem;
    /* color: #e13019 !important; */
}

.menu li .megamenu,
.menu li>ul {
    position: absolute;
    top: -9999px;
    left: 100%;
    margin: 0;
    box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -10px, 0);
}

.menu li>ul {
    min-width: 22.6rem;
    padding: 2rem 0;
    background: #fff;
}

.menu li>ul .submenu>a:after {
    content: "";
    position: absolute;
    right: 22px;
    top: 50%;
    width: 7px;
    height: 7px;
    border: solid #666;
    border-width: 2px 2px 0 0;
    transform: translateY(-50%) rotate(45deg);
}

.menu li>ul li {
    padding: 0 2rem;
}

.menu li .megamenu a,
.menu li>ul a {
    font-weight: 400;
    color: #666;
}

.menu li .megamenu a:hover,
.menu li>ul a:hover {
    color: #0b80d2;
}

.menu .show .megamenu,
.menu .show>ul,
.menu li:hover .megamenu,
.menu li:hover>ul {
    visibility: visible;
    opacity: 1;
    top: -2rem;
    transform: translate3d(0, 0, 0);
}

.menu>li {
    margin-right: 2.3rem;
}

.menu>li:last-child {
    margin-right: 0;
}

.menu>li>a {
    position: relative;
    padding: 1.2rem 0 1.6rem;
    letter-spacing: inherit;
    line-height: 1;
    text-transform: uppercase;
    color: white;
}

.menu>li>a:hover {
    color: #a01300 !important;
    opacity: 1;
    top: 0;
}

/* .menu > li > a:focus  {
    color: #a01300 !important;
    border-left: 0 none !important;
    text-align: left !important;
    opacity: 1;
    top: 0;
} */


element.style {
    padding-inline: 60px;
    text-align: center;
    font-size: 50px;
    margin-top: 60px;
}

.menu>li .megamenu,
.menu>li>ul {
    left: -1.9rem;
}

.menu>li.show .megamenu,
.menu>li.show>ul,
.menu>li:hover .megamenu,
.menu>li:hover>ul {
    top: 100%;
}

.menu-active-underline>li>a:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid;
    transform-origin: right center;
    transform: scale(0, 1);
    transition: transform 0.3s;
}

.menu-active-underline>li.active>a,
.menu-active-underline>li:hover>a {
    color: inherit;
}

.menu-active-underline>li.active>a:before,
.menu-active-underline>li:hover>a:before {
    transform-origin: left center;
    transform: scale(1, 1);
}

.megamenu {
    display: flex;
    padding: 1rem;
    min-width: 92rem;
    background: #fff;
}

.megamenu ul {
    padding: 0;
}

.megamenu .row {
    flex: 1;
    padding: 0 1rem;
}

.megamenu .row>* {
    padding: 1.8rem 1rem 0.8rem;
}

.megamenu .menu-banner {
    padding: 0;
    overflow: hidden;
}

.megamenu .menu-banner figure {
    height: 100%;
}

.megamenu .menu-banner img {
    height: 100%;
    object-fit: cover;
}

.megamenu .menu-banner .btn-link:hover {
    color: #0b80d2;
}

.menu-banner1 .banner-content {
    left: 9%;
}

.menu-banner1 .banner-subtitle,
.menu-banner1 .banner-title {
    font-size: 3.6rem;
}

.menu-banner1 .banner-title {
    margin-bottom: 1.8rem;
    padding-left: 1.2rem;
    position: relative;
}

.menu-banner1 .banner-title span {
    display: inline-block;
    position: absolute;
    left: -0.9rem;
    top: 50%;
    font-size: 1.2rem;
    line-height: 1;
    transform: rotateZ(-90deg) translateX(0.6rem);
    letter-spacing: -0.1em;
}

.menu-banner2 .banner-content {
    bottom: 10%;
}

.menu-banner2 .banner-title {
    margin-bottom: 0.6rem;
    font-size: 2.6rem;
}

.megamenu .menu-banner {
    padding: 0;
    overflow: hidden;
}

.megamenu .menu-banner figure {
    height: 100%;
}

.megamenu .menu-banner img {
    height: 100%;
    object-fit: cover;
}

.megamenu .menu-banner .btn-link:hover {
    color: #0b80d2;
}

.mobile-menu-wrapper {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    font-family: "Open Sans", sans-serif;
    transition: visibility 0.4s;
}

.mobile-menu-container {
    max-width: 296px;
    padding: 2rem 1.5rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #222529;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(-296px);
    transition: transform 0.4s;
}

.mobile-menu-container .mobile-menu {
    margin-bottom: 0.5rem;
    background: #222529;
}

.mobile-menu-container.scrollable::-webkit-scrollbar-thumb,
.mobile-menu-container .sidebar-content::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 7px;
    cursor: pointer;
}

.mobile-menu {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.025em;
    color: #e1e1e1;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.025em;
}

.mobile-menu ul {
    display: none;
    width: 100%;
}

.mobile-menu>li:first-child {
    padding-top: 0.5rem;
}

.mobile-menu>li:last-child {
    padding-bottom: 0.5rem;
}

.mobile-menu li a {
    display: block;
    position: relative;
    padding: 1.3rem 0.6rem 1.3rem 1rem;
}

.mobile-menu li i {
    display: inline-block;
    margin-bottom: 1px;
    font-size: 2rem;
    margin-right: 0.5rem;
    line-height: 0;
    vertical-align: middle;
}

.mobile-menu li:not(:last-child) {
    border-bottom: 1px solid #2e3237;
}

.mobile-menu .active>a,
.mobile-menu .expanded {
    background: #2e3237;
}

.mobile-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0;
    transition: opacity 0.4s;
}

.mobile-menu-close {
    position: fixed;
    left: calc(100vw - 50px);
    top: 25px;
    transition: opacity 0.3s;
    opacity: 0;
}

.mobile-menu-close i {
    font-size: 2.8rem;
    color: #e1e1e1;
}

.mmenu-anim {
    transform: translateY(30%);
}

.mmenu-anim,
.mmenu-anim>li {
    transition: transform 0.4s, opacity 0.3s;
    transition-timing-function: cubic-bezier(0.5, 0, 0.3, 1);
}

.mmenu-anim>li:nth-child(0) {
    opacity: 0;
    transform: translateY(0px);
}

.mmenu-anim>li:first-child {
    opacity: 0;
    transform: translateY(50px);
}

.mmenu-anim>li:nth-child(2) {
    opacity: 0;
    transform: translateY(100px);
}

.mmenu-anim>li:nth-child(3) {
    opacity: 0;
    transform: translateY(150px);
}

.mmenu-anim>li:nth-child(4) {
    opacity: 0;
    transform: translateY(200px);
}

.mmenu-anim>li:nth-child(5) {
    opacity: 0;
    transform: translateY(250px);
}

.mmenu-anim>li:nth-child(6) {
    opacity: 0;
    transform: translateY(300px);
}

.mmenu-anim>li:nth-child(7) {
    opacity: 0;
    transform: translateY(350px);
}

.mmenu-active {
    overflow: hidden;
}

.mmenu-active .mmenu-anim,
.mmenu-active .mmenu-anim>li {
    opacity: 1;
    transform: translateY(0);
}

.mmenu-active .page-wrapper {
    margin-left: 296px;
    margin-right: -296px;
}

.mmenu-active .mobile-menu-wrapper {
    visibility: visible;
}

.mmenu-active .mobile-menu-container {
    transform: translateX(0);
}

.mmenu-active .mobile-menu-overlay {
    opacity: 0.8;
}

.mmenu-active .mobile-menu-close {
    opacity: 1;
}

@media (max-width: 400px) {
    .mobile-menu-close {
        left: calc(100vw - 40px);
        top: 10px;
    }
}

.appear-animate {
    visibility: hidden;
}

.intro-banner {
    min-height: 57.8rem !important;
}

.intro-banner .banner-subtitle .gift {
    margin-left: 0.8rem;
    margin-right: 0.6rem;
    font-size: 2.4em;
}

.intro-banner .banner-title {
    margin-bottom: 3.4rem;
    text-indent: -2px;
    line-height: 1.06;
    width: 650px;
    font-size: 32px !important;
}

.intro-banner .container {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.intro-banner .banner-content {
    display: inline-block;
    padding-top: 100px;
    top: 50%;
    transform: translateY(-50%);
}

.intro-banner .btn-play {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    width: 7.8rem;
    height: 7.8rem;
    background: #222;
    border-radius: 50%;
    vertical-align: middle;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.intro-banner .btn-play svg {
    width: 50px;
    height: 50px;
    fill: #fff;
}

.intro-banner .custom-absolute-img1 {
    position: absolute;
    margin: 0;
    left: 52.7%;
    right: 0;
    bottom: 0;
    opacity: 0;
}

.intro-banner .custom-absolute-img1 img {
    min-height: 600px;
    object-fit: cover;
    object-position: left top;
    box-shadow: 0px 0px 35px 0px #0000003d;
    border-top-left-radius: 42px;
    border-right: 0px;
    border-bottom: 0px;
}

.intro-banner .custom-absolute-img2 {
    position: absolute;
    left: 57.38%;
    margin: 0;
    max-width: 40%;
    bottom: -14rem;
}

.intro-banner .custom-absolute-img2 img {
    position: relative;
    z-index: 1;
}

.intro-banner .custom-absolute-img2 figure {
    top: -50px;
}

.intro-banner .custom-absolute-img2 figure:before {
    content: "";
    position: absolute;
    left: 10px;
    right: 30px;
    top: 10px;
    bottom: 10px;
}

.intro-banner .mobile-content {
    position: absolute;
    left: 27%;
    right: 6.8%;
    top: 1.8%;
    bottom: 12%;
}

.intro-banner .mobile-image {
    position: relative;
    z-index: 1;
}

.intro-banner .video-desc {
    font-size: 1.6rem;
    line-height: 2.2rem;
    vertical-align: middle;
}

.intro-banner .video-desc span {
    font-size: 1.4rem;
}

.footer-bottom {
    padding: 3.35rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.footer-bottom p {
    font-size: 1.6rem;
    letter-spacing: -0.01em;
}

@media (min-width: 1366px) {
    .d-xxl-none {
        display: none !important;
    }

    .d-xxl-block {
        display: block !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-inline-block {
        display: inline-block !important;
    }
}

.same-height .flip-card,
.same-height .icon-box {
    height: 100%;
}

.text-lg {
    font-size: 1.2em;
}

.header .divider {
    height: 62px;
    margin-right: 2.4rem;
    background: rgba(255, 255, 255, 0.3);
}

.header-middle.fixed {
    border-bottom-color: transparent;
}

.change-log {
    font-size: 1.4rem;
    margin-bottom: 4px;
}

a {
    text-decoration: none !important;
}

.main-features h2 span {
    margin-left: 1rem;
    color: #e13019 !important;
}

.bg-gradient {
    background: linear-gradient(to top left, #ff472b, #ff472b) !important;
}

.flip-card .flip-content {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: inherit;
    transform: translate3d(0, 0, 60px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-features .icon-box {
    padding: 0px;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 230px;
    background: #e13019 !important;
    width: 289px;
    display: flex;
}

.header .btn-purchase {
    display: inline-block;
    border: 0px solid #ffffff;
    outline: 0;
    margin-bottom: 4px;
    border-width: 1px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background-color: #e13019;
    border-color: #ffffff;
    color: #ffffff !important;
    padding: 1em 2em;
    font-weight: 700;
    font-size: 1.4rem;
    font-family: Poppins, sans-serif;
    letter-spacing: -0.025em;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s, box-shadow 0.3s;
    white-space: nowrap;
    cursor: pointer;
}

.header .btn-purchase:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #d3402c !important;
}

.sticky-header.fixed .btn:hover {
    background-color: #0b80d2;
}

.change-log {
    font-size: inherit;
    letter-spacing: -0.02em;
    margin-bottom: 2px;
}

.radio-labels {
    font-size: 1.4rem;
    font-style: italic;
    color: white;
    font-size: medium;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip:focus {
  position: static;
  width: auto;
  height: auto;
}

/* responsive ------------ */
@media screen and (max-width: 1268px) {
    .child2FormQuota {
        width: 100vw;
        /* margin-top: -700px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: -57vw;
        align-items: center;
        position: relative;
        top: 420px;
    }

    .child2FormQuota .subChild1 {
        display: flex;
        flex-direction: column;
        background-color: #181634;
        color: white;
        padding: 40px;
        border-radius: 10px;
        position: relative;
        top: 150px;
        width: 92%;
        box-shadow: 0px 0px 28px 0px #000000c2;
        z-index: 2;
        margin-top: -90px;
        margin-left: 0px;
    }

    .intro-banner .container {
        padding-top: 5rem;
        padding-bottom: 5rem;
        display: flex;
        justify-content: center;
    }

    .sliderHome {
        height: 700px;
    }

    .sliderHome img {
        width: 100%;
        height: 590px;
    }

    .section2 {
        margin-top: 560px;
    }

    .row .mb-5 {
        display: flex;
        justify-content: center;
    }

    .main-features h2 {
        text-align: center !important;
    }
}

@media screen and (max-width: 698px) {
    .banner-title {
        width: 470px !important;
        margin: 0 auto;
    }

    .page-content .banner-content .child1 div button {
        width: 467px !important;
    }

    .child2FormQuota .subChild1 {
        margin-top: 50px;
    }

    .page-content {
        margin-top: -668px !important;
        position: relative;
    }

    .child1 .subChild1ContentBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
    }

    .page-content .banner-content .child1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 124%;
    }

    .page-content .banner-content .child1 div input {
        font-size: 20px;
        width: 100%;
        padding: 16px;
        border-radius: 6px;
        border: 0px;
        margin-right: 0px;
    }

    .page-content .banner-content .child1 div button {
        padding: 12px;
        border: 0px;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.2s;
        font-weight: bold;
        font-size: 21px;
        margin-top: 15px;
        width: 100%;
    }
}

@media screen and (max-width: 530px) {
    .intro-banner .banner-title {
        font-size: 16px !important;
        width: 280px !important;
    }

    .page-content .banner-content .child1 div button {
        width: 317px !important;
    }

    .intro-banner .banner-subtitle {
        font-size: 2em;
        width: 320px;
        margin: 0 auto;
    }

    .sliderHome li {
        width: 100%;
        list-style: none;
        overflow: hidden;
    }

    .intro-banner .banner-content {
        transform: none;
        margin-top: -40px;
    }

    .content3 p {
        padding-inline: 3%;
        text-align: center;
        margin-top: 30px;
    }

    .content4 p {
        font-size: 22px;
        text-align: center;
        width: 92%;
        margin: 0 auto;
    }

    .content3 h1 {
        padding-inline: 60px;
        text-align: center;
        font-size: 37px;
        margin-top: 90px;
    }

    .sliderHome img {
        min-width: 1340px;
        /* height: 440px; */
        height: 800px;
    }

    .child2FormQuota .subChild1 {
        display: flex;
        flex-direction: column;
        background-color: #181634;
        color: white;
        padding: 40px;
        border-radius: 10px;
        position: relative;
        top: 185px;
        width: 92%;
        box-shadow: 0px 0px 28px 0px #000000c2;
        z-index: 2;
        margin-top: -90px;
        margin-left: 0px;
    }

    .section2 {
        margin-top: 370px;
    }

    .page-content {
        margin-top: -638px !important;
        position: relative;
    }
}

.header-leftTextChange {
    padding-left: 40px;
    font-size: 14px;
    text-align: left;
    padding-bottom: 40px;
}

@media screen and (max-width: 990px) {
    .menuHeaderAll {
        display: none !important;
    }

    .MenuMovilContent {
        display: flex;
    }

    .d-none {
        display: flex !important;
    }

    .main-nav {
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .header .container,
    .header .container-fluid,
    .header .inner-wrap {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .header .btn-purchase {
        display: flex !important;
    }

    .pts1 {
        padding-top: 0;
    }
}

@media screen and (max-width: 558px) {
    .menu {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .header-leftTextChange {
        padding-left: 0px !important;
        font-size: 14px;
        text-align: center;
        padding-bottom: 40px;
    }

    .main-nav .menu>li {
        margin: 0px !important;
    }
}

@media screen and (max-width: 778px) {

    .header .divider,
    .header .main-nav {
        display: flex !important;
    }

    .subsection4Other {
        text-align: center;
        padding: 20px;
    }
}

@media screen and (max-width: 844px) {
    .ContentAllFSC {
        display: flex;
        flex-direction: column;
    }

    .LineCardText {
        width: 100%;
        text-align: center;
        margin-top: 40px;
        font-size: 30px;
        font-weight: bold;
    }

    .ContentAllFSC .FSCHome {
        width: 100%;
        max-width: none;
    }
}

@media screen and (max-width: 1100px) {
    .page-content {
        margin-top: -578px;
        position: relative;
    }
}