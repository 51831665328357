.modal-component {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.5);
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out, background-color 0.2s ease-out, ;
}

.modal-component.show {
  opacity: 1;
  visibility: visible;
}

.modal-component.show .modal-content {
  scale: 1;
}

.modal-component.small .modal-content {
  width: min(272px, calc(100vw - 32px));
}

.modal-component.medium .modal-content {
  width: min(400px, calc(100vw - 32px));
}

.modal-component.large .modal-content {
  width: min(680px, calc(100vw - 32px));
}

.modal-component.extra-large .modal-content {
  width: min(920px, calc(100vw - 32px));
}

.modal-component.fluid .modal-content {
  width: calc(100vw - 160px);
}

.modal-component .modal-content {
  position: relative;
  scale: 0.5;
  background-color: white;
  transition: scale 0.2s ease-out, ;
}

.modal-component .modal-content .modal-header {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 1em 3em;
  background-color: #E13019;
}

.modal-component .modal-content .modal-header p {
  margin: 0;
}

.modal-component .modal-content .modal-header img {
  height: 2em;
  aspect-ratio: 1;
  filter: invert(100%);
}

.modal-component .modal-content .modal-header p {
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5;
  color: white;
}

.modal-component .modal-content .modal-body {
  padding: 1.5em 3em 0 6em;
}

.modal-component .modal-content .modal-body hr {
  opacity: 0.5;
}

.modal-component .modal-content .modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 1.5em 3em 1.5em 3em;
}

.modal-component .modal-content .modal-footer button {
  font-weight: 600;
  font-size: 1em;
  text-transform: uppercase;
  color: #595959;
  background: none;
  outline: none;
  box-shadow: none;
  border: none;
}